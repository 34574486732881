import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
export const StyledTitleOnlyParagraphComponent = styled.div`
  max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
  margin: auto;

  .title-only-paragraph-wrapper {
    max-width: 1100px;
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 0px 32px;
  }
`;
